$(document).ready(function() {

    // var documentHeight = $(window).height();
    // var menuHeight = $('.section-menu').outerHeight();
    // var minHeight = documentHeight - menuHeight;
    //
    // setMinHeight($('.js-banner'), minHeight);
    //
    // $(window).on('resize', function() {
    //     documentHeight = $(window).height();
    //     menuHeight = $('.section-menu').outerHeight();
    //     minHeight = documentHeight - menuHeight;
    //
    //     setMinHeight($('.js-banner'), minHeight);
    // });
});
