var readyToShowFacebook = false;

$(document).ready(function()
{
    setTimeout(
    function()
    {
        if(!Cookies.get('facebook_popup_shown') && !Cookies.get('facebook_popup_do_not_show_again'))
        {
            readyToShowFacebook = true;
        }
    }, 2000);

    $('.js-facebook-popup-close-button, .js-popup-background').on('click', function(e) {
        e.preventDefault();
        CloseFacebookPopup();
    });

    $('.js-facebook-dont-show-again').on('click', function(e) {
        e.preventDefault();
        CloseFacebookPopup(true);
    });

    $(document).mousemove(function(e) {
        if(!readyToShowFacebook)
        {
            return;
        }

        if(readyToShowFacebook && e.clientY < 40)
        {
            ShowFacebookPopup();
        }
    });

    $(window).on('scroll', function(){

        if(!$('.js-facebook-popup-trigger').length)
        {
            return;
        }

        var scrollTop = $(window).scrollTop();
        var footerOffset = $('.js-facebook-popup-trigger').last().offset().top;

        if(footerOffset - scrollTop < 700 && readyToShowFacebook)
        {
            ShowFacebookPopup();
        }
    });

});

function ShowFacebookPopup()
{
    if(!$('.js-facebook-popup').length || !$('.js-popup-background').length)
    {
        return;
    }

    readyToShowFacebook = false;
    $('.js-facebook-popup').removeClass('d-none');
    $('.js-popup-background').removeClass('d-none');
    $('.js-facebook-popup').addClass('fadeIn');
    $('.js-popup-background').addClass('fadeIn');

    Cookies.set('facebook_popup_shown', 1, {expires: 2});
}

function CloseFacebookPopup(doNotShowAgain)
{
    $('.js-facebook-popup').addClass('d-none');
    $('.js-popup-background').addClass('d-none');

    if(doNotShowAgain)
    {
        Cookies.set('facebook_popup_do_not_show_again', 1, {expires: 365});
    }
}