$(document).ready(function() {

    $('.js-ww-mobile-menu-button').on('click', function() {
        ShowElement(0, 'slideInRight', $('.js-ww-mobile-menu'));
    });

    $('.js-ww-menu-close, a').on('click', function() {
        HideElement(500, 'slideOutRight', $('.js-ww-mobile-menu'));
    });

});

function ShowElement(delay, className, $element) {
    setTimeout(function () {
        $element.removeClass('d-none');
        $element.addClass(className);
    }, delay);
}

function HideElement(delay, className, $element) {
    $element.addClass(className);
    setTimeout(function () {
        $element.addClass('d-none');
        $element.removeClass(className);
    }, delay);
}