var defaultTop;

$(document).ready(function(){
    defaultTop = parseInt($('.js-social-buttons-dummy').css('top'));
});

$(window).scroll(function() {

    if(!$('.js-social-buttons-dummy').length)
    {
        return;
    }

    var positionTop = $('.js-social-buttons-dummy').offset().top - $(window).scrollTop();

    if(positionTop < defaultTop && !$('.js-social-buttons').hasClass('fixed'))
    {
        $('.js-social-buttons').addClass('fixed');
    }
    else if(positionTop >= defaultTop && $('.js-social-buttons').hasClass('fixed'))
    {
        $('.js-social-buttons').removeClass('fixed');
    }

});