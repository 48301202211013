
$(document).ready(function() {

    setTimeout(function() {
        if(!Cookies.get('gdpr_accepted'))
        {
            $('.js-gdpr-agreement').removeClass('d-none');
            $('.js-gdpr-agreement').addClass('slideInUp');
            $('.js-gdpr-whitespace').removeClass('d-none');
        }
    }, 1500);

    $('.js-gdpr-ok').on('click', function() {
        $('.js-gdpr-agreement').addClass('d-none');
        $('.js-gdpr-whitespace').addClass('d-none');
        Cookies.set('gdpr_accepted', 1, {expires: 360});
    });

});